<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row class="products-row">
      <v-col
        cols="8"
        offset="2">
        <v-row>
          <v-col
            cols="12"
            class="pb-0">
            <h3>ID: {{ avoidNullValue(member, 'id') }}</h3>
          </v-col>
          <v-col
            cols="6"
            class="pr-1"
            style="padding-top: 34px;">
            <vue-tel-input-vuetify
              v-model="form.tel"
              label="เบอร์โทรศัพท์"
              default-country="th"
              dense
              outlined
              :disabled="!telEditable"
              @country-changed="countryChange($event)" />
            <!-- <tel-input
              :default-value="defaultTelInfo"
              :read-only="!telEditable"
              label="เบอร์โทรศัพท์"
              class="mt-1 phone-sel birth-date-picker"
              @telInfo="setTelInfo" /> -->
          </v-col>
          <v-col
            cols="4"
            class="pl-0">
            <v-btn
              :disabled="telEditable"
              class="edit-tel-btn"
              color="secondary"
              height="40px"
              @click="tryToEditTel()">
              แก้ไขเบอร์โทรศัพท์
            </v-btn>
          </v-col>
          <v-col cols="2" />
          <v-col cols="6">
            <v-text-field
              v-model="form.firstName"
              label="ชื่อ"
              dense
              hide-details
              outlined />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.lastName"
              label="นามสกุล"
              dense
              hide-details
              outlined />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.email"
              label="อีเมล"
              dense
              hide-details
              outlined />
          </v-col>
          <!-- <v-col cols="6">
            <v-text-field
              v-model="form.cardId"
              label="รหัสบัตรสมาชิก"
              dense
              hide-details
              outlined />
          </v-col> -->
          <v-col cols="6">
            <v-menu
              ref="menu"
              v-model="birthDateMenu"
              :close-on-content-click="false"
              :return-value.sync="form.birthDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="birthDateCompute"
                  label="วันเกิด"
                  dense
                  hide-details
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on" />
              </template>
              <v-date-picker
                v-model="form.birthDate"
                :max="$dayjs(new Date()).format()"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="birthDateMenu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(form.birthDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="form.gender"
              :items="genders"
              label="เพศ"
              dense
              hide-details
              outlined />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="form.type"
              :items="types"
              label="ประเภทสมาชิก"
              dense
              hide-details
              outlined />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="form.tags"
              :items="tags"
              label="แท็กกลุ่มลูกค้า"
              multiple
              deletable-chips
              small-chips
              color="secondary"
              dense
              hide-details
              outlined />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="form.note"
              outlined
              label="หมายเหตุ"
              dense
              hide-details />
          </v-col>
          <v-col
            v-for="(address, index) in form.addresses"
            :key="`addresses-${index}`"
            cols="12">
            <address-input
              v-model="form.addresses[index]"
              :index="index"
              @remove="removeAddress($event)" />
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              block
              @click="addAddresses()">
              เพิ่มที่อยู่
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="error"
              block
              @click="deleteMember()">
              ลบ
            </v-btn>
          </v-col>
          <v-col cols="8">
            <v-btn
              color="success"
              block
              @click="updateMember()">
              แก้ไข
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MemberProvider from '@/resources/MemberProvider'
import MemberTagsProvider from '@/resources/MemberTagsProvider'
// import TelInput from '../components/TelInput.vue'
import AddressInput from '../components/AddressInput.vue'

const MemberService = new MemberProvider()
const MemberTagsService = new MemberTagsProvider()

export default {
  components: {
    // TelInput,
    AddressInput
  },
  data () {
    return {
      telEditable: false,
      birthDateMenu: false,
      tags: [],
      genders: [
        { text: 'หญิง', value: 'female' },
        { text: 'ชาย', value: 'male' },
        { text: 'ไม่ระบุ', value: 'N/A' }
      ],
      types: [
        { text: 'Member', value: 'normal' },
        { text: 'VIP', value: 'vip' },
        { text: 'VVIP', value: 'vvip' }
      ],
      form: {
        firstName: null,
        lastName: null,
        birthDate: null,
        email: null,
        tel: null,
        telInfo: null,
        type: 'normal',
        gender: null,
        tags: []
      }
    }
  },
  computed: {
    ...mapGetters({
      member: 'Member/member'
    }),
    defaultTelInfo () {
      let tel = this.member?.tel
      const telInfo = this.member?.telInfo || {}

      if (telInfo.dialCode === '66' && tel.indexOf('+66') !== -1) {
        tel = `0${tel.slice(3)}`
      }
      return {
        tel,
        telInfo
      }
    },
    birthDateCompute () {
      return this.form.birthDate ? this.$dayjs(this.form.birthDate).format('DD MMM YYYY') : null
    }
  },
  watch: {
    'form.tel': {
      handler (value) {
        if (value.length === 12 && this.form.telInfo.dialCode === '66') {
          this.form.tel = this.form.tel.includes('+66') ? this.form.tel : `+66${this.form.tel.slice(0)}`
        }
      },
      deep: true
    }
  },
  mounted () {
    this.getMemberTags()

    this.form = {
      ...this.form,
      ...this.member
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    async getMemberTags () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GET MEMBER TAGS...'
        })

        const { data } = await MemberTagsService.getMemberTags({ page: 1, limit: 9999 })
        this.tags = data?.results?.map((v) => v.name) || []
      } catch (error) {
        console.error('getMemberTags', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    tryToEditTel () {
      this.setModal({
        value: true,
        title: 'Edit tel no',
        message: 'Do you want to edit tel no?',
        confirmText: 'Confirm',
        confirmType: 'success',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: this.enableTelEdit
      })
    },
    enableTelEdit () {
      this.telEditable = true
    },
    avoidNullValue (object, properties, defaultValue = '-') {
      return object[properties] || defaultValue
    },
    dateCompute (date) {
      return date ? this.$dayjs(date).format('DD MMM YYYY') : '-'
    },
    countryChange (telInfo) {
      this.form.telInfo = telInfo
    },
    setTelInfo (tel, telInfo) {
      this.form.tel = this.setThTel(tel, telInfo)
      this.form.telInfo = telInfo
    },
    async deleteMember () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'DELETING MEMBER...'
        })

        const respond = await MemberService.deleteMember(this.member.id)
        if (respond.message !== 'done') {
          this.setSnackbar({
            value: true,
            message: `[DELETE-MEMBER-ERROR]: ${respond.message}`,
            type: 'error'
          })
        } else {
          this.setSnackbar({
            value: true,
            message: 'ลบสมาชิกแล้ว',
            type: 'success'
          })

          this.$router.push({ name: 'MemberManagement' })
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[DELETE MEMBER ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async updateMember () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'UPDATING MEMBER...'
        })

        this.form.tel = this.form.tel.trim().replaceAll(' ', '').replaceAll('-', '')
        const respond = await MemberService.updateMember(this.member.id, this.form)

        if (respond.message !== 'done') {
          this.setSnackbar({
            value: true,
            message: `[UPDATE-MEMBER-ERROR]: ${respond.message}`,
            type: 'error'
          })
        } else {
          this.setSnackbar({
            value: true,
            message: 'แก้ไขสมาชิกสำเร็จ',
            type: 'success'
          })

          this.$router.push({ name: 'MemberManagement' })
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[UPDATE MEMBER ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    setThTel (tel, telInfo) {
      let result = tel
      if (telInfo?.dialCode === '66' && tel?.length === 10 && tel[0] === '0') {
        result = `+${telInfo.dialCode}${tel.slice(1)}`
      } else if (telInfo?.dialCode === '66' && tel?.length === 9 && tel[0] !== '0') {
        result = `+${telInfo.dialCode}${tel}`
      }

      return result
    },
    addAddresses () {
      this.form.addresses.push({
        address: '',
        address2: '',
        contactNo: '',
        country: '',
        defaultShipping: false,
        district: '',
        firstName: '',
        lastName: '',
        note: '',
        postcode: '',
        province: '',
        states: '',
        tax_id: '',
        title: ''
      })
    },
    removeAddress (index) {
      this.form.addresses.splice(index, 1)
    }
  }
}
</script>

<style>
.edit-tel-btn {
  margin-top: 22px;
}
</style>
