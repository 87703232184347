<template>
  <v-row>
    <v-col
      cols="12"
      class="d-flex">
      <h3>ที่อยู่ {{ index+1 }}</h3>
      <v-spacer />
      <v-btn
        small
        color="error"
        @click="removeAddress()">
        <v-icon>mdi-delete</v-icon> Delete
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-textarea
        v-model="form.address"
        outlined
        label="ที่อยู่"
        dense
        hide-details />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="form.district"
        label="ตำบล/แขวง"
        dense
        hide-details
        outlined />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="form.states"
        label="อำเภอ/เขต"
        dense
        hide-details
        outlined />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="form.province"
        label="จังหวัด"
        dense
        hide-details
        outlined />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="form.country"
        label="ประเทศ"
        dense
        hide-details
        outlined />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="form.postcode"
        label="รหัสไปรษณีย์"
        dense
        hide-details
        outlined
        @keypress="$keypressNumber($event)" />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="form.contactNo"
        label="เบอร์โทรศัพท์ติดต่อ"
        dense
        hide-details
        outlined
        @keypress="$keypressNumber($event)" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    removeAddress () {
      this.$emit('remove', this.index)
    }
  }
}
</script>
